.edu-box .swipper_section .articles__title h1:nth-child(3) {
  display: block !important;
}
.edu-box .swipper_section .articles__title h1:nth-child(1) {
  display: none;
}

.edu-box .swipper_section {
  padding-block: 2rem;
}

.education__title {
  font-weight: 900;
  text-align: center;
}

/* Education Search Result */
.education_search_result,
.education__swipper {
  padding-block: 2rem;
}

.education_search_result .row {
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
}

.education_search_result .education_single-card,
.education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 22px;
  width: 31% !important;

  /* width: auto;
  max-width: 100%;
  height: auto;
  max-height: 236px; */
}

.education_card_info,
.education_swiper_info,
.swipper-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 0.5rem;

  width: 40%;
  height: 215px;
}

.education_swiper_info {
  gap: 1rem;
  height: 265px;
  flex-direction: row;
  width: 45%;
}

.swipper-card-content {
  height: 265px;
  width: 100%;
  justify-content: space-around;
}

.education_card_info .main_info,
.swipper-card-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}

/* .swipper-card-info{


width: 153.25px;


} */

.education_card_info .main_info h5,
.education_card_info .main_info h6,
.swipper-card-info h5,
.swipper-card-info h6 {
  width: 100%;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #37e0b1;
}

.swipper-card-info h5,
.swipper-card-info h6 {
  font-size: 10px;
  line-height: 15px;
}

.education_card_info .main_info h4,
.swipper-card-info h4 {
  width: 100%;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #012e54;
}

/* .swipper-card-info h4 {
  font-size: 16px;
} */

.education_card_info .main_info h6,
.swipper-card-info h6 {
  color: #000000;
}

.education_card_info p {
  width: 100%;
  font-family: "Trebuchet MS";
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #2d2e2e;
  margin-bottom: 0.5rem;
}

.arrow_link {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 9px;
  gap: 9px;

  width: 35px;
  height: 35px;

  background: #f1fffb;
  border-radius: 3px;
  cursor: pointer;
}

.arrow_link i {
  font-size: 18px;
  color: var(--basic-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* gap: 0.5rem; */
}

.arrow_link:hover {
  background-color: var(--hover-color);
  transition: all 0.8s var(--transition);
  transform: translateY(-2px);
}

.arrow_link i:hover {
  color: var(--body-color);
}

.education_card_image {
  width: 55%;
  height: 236.95px;
  border-radius: 7px;
  position: relative;
  display: inline-block;
}

.education_swiper_image {
  height: 283.1px;
  border-radius: 7px;
  width: 55%;

  position: relative;
  display: inline-block;
}

.education_card_image img,
.education_swiper_image img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.education_card_image .top-rated__overlay,
.education_swiper_image .top-rated__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.education_card_image .premium__tag,
.education_swiper_image .premium__tag {
  left: 0;
  top: unset;
}

.education_card_image .price__tag,
.education_swiper_image .price__tag {
  position: absolute;
  width: 86px;
  height: 34px;
  /* right: -15.8rem;
  top: -3.5rem; */
  /* z-index: 2; */

  bottom: 12px;
  right: 0;

  background: #37e0b1;
  box-shadow: 0px 23.1172px 48.1608px rgba(0, 0, 0, 0.1);
  border-radius: 4.81608px 0px 0px 4.81608px;

  font-family: "Montserrat";
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;
}

/* .education_swiper_image .price__tag {
  right: -14.76rem;
  top: -3rem;
} */

/* Swipper */
.education__swipper {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 36px;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  padding-block: 2rem;
  padding-inline: 3rem;
}

.education__swipper .articles__title h3 {
  color: var(--basic-color);
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  align-items: center;
}

.education__swipper .row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 24px;
  width: 100%;
  /* height: 361.1px; */
}

.education__swipper .col-lg-12:nth-child(2) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 3rem;
}

.education_swiper_info h3 {
  width: 50px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 47.5px;
  line-height: 58px;
  color: #012e54;
}

.swipper-card-content .closeIn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 5px; */
}

.swipper-card-content .closeIn main:nth-child(1) {
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  color: #ffa800;
}

.swipper-card-content .closeIn main:nth-child(2) {
  width: 100%;
  height: 25px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 7px;
  gap: 8px;

  background: #ffa800;
  border-radius: 3px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}

/* .education__swipper .col-lg-12:nth-child(2) {

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 22.8px;
  isolation: isolate;

  width: 31% !important;
} */

/* Ad card */
.ad-box {
  height: 300px;

  background: #1b283f;
  border-radius: 12px;
}
.ad__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 2rem 2rem 3rem;
  gap: 10px;
  width: 100%;
}

.ad__container h1 {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #ffffff;
}

.ad__container p {
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.6);
}

.ad__container button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 18px;
  gap: 10px;

  width: 109px;
  height: 41px;

  background: #3dcbb1;
  border-radius: 3px;

  font-family: "Gilroy";
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
}

.ad_image {
  position: absolute;
  left: 46.3%;
  top: -4px;
  width: 55% !important;
  height: 100%;
  border-radius: 20px;
}

/* .ad_image img {
} */

/* Responsive */

@media screen and (max-width: 1450px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 75px;
  }
}

@media screen and (max-width: 1375px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 70px;
  }
}

@media screen and (max-width: 1280px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 65px;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 48% !important;
  }

  .education_card_info {
    height: auto;
  }

  .education_card_info .main_info h4
     /* .swipper-card-info h4  */ {
    font-size: 14px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 10px;
  }

  .education_card_image {
    height: 225px;
  }

  .ad__container {
    width: 90%;
  }
  .ad__container h1 {
    font-size: 30px;
  }

  .ad__container p {
    font-size: 22px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 60px;
  }
}

@media screen and (max-width: 1140px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 55px;
  }

  .education__swipper .articles__title .play__btns div i {
    font-size: 1.8rem !important;
    top: 0.6rem !important;
  }

  .education__swipper .articles__title .play__btns div {
    width: 45px;
    height: 45px;
  }

  .education__swipper .articles__title .play__btns {
    width: 100px;
    height: 45px;
  }

  .education_card_info .main_info h4
     /* .swipper-card-info h4  */ {
    font-size: 13px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 9.5px;
  }

  .education_card_info p {
    font-size: 11.5px;
    line-height: 18px;
  }

  .education_card_image {
    height: 220px;
  }

  .ad__container {
    width: 85%;
    padding: 1rem 1rem 1rem 2rem;
  }

  .ad-box {
    height: 285px;
  }

  .ad__container h1 {
    font-size: 28px;
  }

  .ad__container p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 1040px) {
  .education_card_info .main_info h4
  /* .swipper-card-info h4  */ {
    font-size: 11.5px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 8.5px;
  }

  .education_card_info p {
    font-size: 10.5px;
    line-height: 15px;
  }

  /* .education_card_image {
    height: 200px;
  } */
}

@media (max-width: 1024px) {
  .education_search_result .education_single-card {
    width: 30% !important;
  }

  .education__title {
    font-size: 55px;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 47% !important;
  }

  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 50px;
  }

  .logo__swiper .logo__circle {
    width: 100px;
    height: 135px;
  }

  .ad__container {
    width: 85%;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }

  .ad-box {
    height: 270px;
  }

  .ad__container h1 {
    font-size: 25px;
  }

  .ad__container p {
    font-size: 18px;
    line-height: 25px;
  }
}

@media (max-width: 992px) {
  .ad-row {
    display: none !important;
  }
  .education_search_result .education_single-card {
    width: 47% !important;
  }
  /* .education_search_result .education_single-card {
    width: 30% !important;
  } */
  .education_card_info .main_info h4
  /* .swipper-card-info h4  */ {
    font-size: 14px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 11px;
  }

  .education_card_info p {
    font-size: 12px;
    line-height: 16px;
  }

  .education_card_image {
    height: 250px;
  }

  .education__swipper .articles__title .play__btns div i {
    font-size: 1.7rem !important;
    top: 0.5rem !important;
  }

  .education__swipper .articles__title .play__btns div {
    width: 40px !important;
    height: 40px !important;
  }
}

@media (max-width: 922px) {
  .education_swiper_info {
    height: 250px;
    justify-content: normal;
  }
  .education_swiper_info h3 {
    width: 40px;
    font-size: 45px;
    line-height: 55px;
  }

  .swipper-card-content {
    height: 250px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6,
  .swipper-card-info h5,
  .swipper-card-info h6 {
    font-size: 9px;
  }

  .education_card_info .main_info h4,
  .swipper-card-info h4 {
    font-size: 14px;
    line-height: 17px;
  }

  .swipper-card-content .closeIn {
    width: 100px;
    margin-bottom: 0;
  }
  .swipper-card-content .closeIn main:nth-child(1) {
    font-size: 12px;
    line-height: 16px;
  }

  .swipper-card-content .closeIn main:nth-child(2) {
    font-size: 9px;
    line-height: 12px;
  }
}

@media (max-width: 800px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 38px;
  }
  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 55% !important;
  }
}

@media (max-width: 768px) {
  .education__title {
    font-size: 40px;
  }
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 33px !important;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 65% !important;
  }

  .education_card_info .main_info h4
  /* .swipper-card-info h4  */ {
    font-size: 12px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 10px;
  }

  .education_card_info p {
    font-size: 12px;
    line-height: 15px;
  }

  /* .education_card_image {
    height: 230px;
  } */

  .education_swiper_image {
    height: 245px !important;
  }

  .education_card_image .price__tag,
  .education_swiper_image .price__tag {
    width: 70px;
    height: 28px;
    font-size: 10px;
  }

  .education_card_image .top-rated__overlay .premium__tag,
  .education_swiper_image .top-rated__overlay .premium__tag {
    width: 40px;
    height: 30px;
  }

  .education_card_image .top-rated__overlay .premium__tag img,
  .education_swiper_image .top-rated__overlay .premium__tag img {
    width: 20px;
    height: 22px;
    left: 10px;
    top: 3px;
  }
}

@media (max-width: 700px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 25px !important;
  }
  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 65% !important;
  }

  .education__swipper .articles__title h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .education__swipper .articles__title .play__btns div {
    width: 35px !important;
    height: 35px !important;
  }
  .education__swipper .articles__title .play__btns div i {
    font-size: 1.5rem !important;
    top: 0.4rem !important;
  }
  .education_search_result,
  .education__swipper {
    padding-inline: 1rem;
  }
}

@media (max-width: 645px) {
  .education_card_info .main_info h4
  /* .swipper-card-info h4  */ {
    font-size: 10.5px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 9px;
  }

  .education_card_info p {
    font-size: 10px;
    line-height: 15px;
  }

  .education_card_image {
    height: 220px;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 80% !important;
  }
}

@media (max-width: 565px) {
  .education_search_result .row {
    justify-content: center;
  }
  .education_search_result .education_single-card {
    width: 90% !important;
  }

  .education_card_info .main_info h4
/* .swipper-card-info h4  */ {
    font-size: 14px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 12px;
  }

  .education_card_info p {
    font-size: 14px;
    line-height: 15px;
  }

  .education_card_image {
    height: 250px;
  }

  .education_card_image .price__tag,
  .education_swiper_image .price__tag {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }

  .education_card_image .top-rated__overlay .premium__tag,
  .education_swiper_image .top-rated__overlay .premium__tag {
    width: 45px;
    height: 35px;
  }

  .education_card_image .top-rated__overlay .premium__tag img,
  .education_swiper_image .top-rated__overlay .premium__tag img {
    width: 22px;
    height: 24px;
    left: 10px;
    top: 6px;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 85% !important;
  }
}

@media (max-width: 575px) {
  .edu-box .swipper_section,
  .education__swipper {
    padding-inline: 0.5rem;
  }
  .edu-box .swipper_section .articles__title,
  .education__swipper .articles__title {
    display: block;
    height: 60px;
  }
  .education_search_result .education_single-card {
    width: 100% !important;
  }

  .education__swipper .articles__title {
    height: 30px;
  }

  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 30px !important;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 80% !important;
  }

  .education__swipper .swiper-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 522px) {
  .education__title {
    font-size: 30px;
  }
  .education_search_result .education_single-card {
    width: 100% !important;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 90% !important;
  }

  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 25px !important;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 100% !important;
  }
  .education__swipper .articles__title h3 {
    font-size: 25px;
  }
}

@media (max-width: 425px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 22px !important;
  }

  .education__swipper .articles__title h3 {
    font-size: 20px;
  }

  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 100% !important;
  }

  .education__swipper .swiper-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 375px) {
  .edu-box .swipper_section .articles__title h1:nth-child(3) {
    font-size: 19px !important;
  }
  .education__swipper .col-lg-12:nth-child(2) .col-lg-3 {
    width: 100% !important;
    gap: 3rem;
  }

  .education__swipper .swiper-wrapper {
    width: 100% !important;
  }

  .education_card_info .main_info h4
  /* .swipper-card-info h4  */ {
    font-size: 12px;
  }

  .education_swiper_info h3 {
    width: 35px;
    font-size: 40px;
    line-height: 55px;
  }

  .swipper-card-info h4 {
    font-size: 11px !important;
    line-height: 17px;
  }

  .education_card_info .main_info h5,
  .education_card_info .main_info h6 {
    font-size: 10px;
  }

  .education_card_info p {
    font-size: 12px;
    line-height: 15px;
  }

  .education_card_image {
    height: 230px;
  }

  .education_swiper_info {
    height: 230px;
    width: 40%;
  }

  .education_swiper_image {
    height: 245px;
    width: 60%;
  }

  .education_card_image .price__tag,
  .education_swiper_image .price__tag {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }

  .education_card_image .top-rated__overlay .premium__tag,
  .education_swiper_image .top-rated__overlay .premium__tag {
    width: 45px;
    height: 35px;
  }

  .education_card_image .top-rated__overlay .premium__tag img,
  .education_swiper_image .top-rated__overlay .premium__tag img {
    width: 22px;
    height: 24px;
    left: 10px;
    top: 6px;
  }
}

@media (max-width: 330) {
  .education_swiper_info h3 {
    width: 30px;
    font-size: 30px;
    line-height: 50px;
  }

  .swipper-card-info h4 {
    font-size: 9px;
    line-height: 14px;
  }
}
