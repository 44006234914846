.footer {
  padding-block-start: 2rem;
  padding-block-end: 3rem;
  /* position: relative; */
}

.footer__info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
}
.footer__title {
  color: var(--section-color);
  font-family: var(--section-title-font);
  font-weight: var(--weight-600);
  font-size: 1rem;
  line-height: 1.4rem;
}

.footer__decsription p {
  color: var(--text-color);
  font-family: "Inter";
  font-weight: var(--weight-400);
  font-size: 0.865rem;
  line-height: 1.4rem;
  width: 85%;
}

.footer__link-title {
  color: var(--basic-color);
  font-family: var(--section-title-font);
  font-weight: var(--weight-600);
  font-size: 1rem;
  line-height: 1.4rem;
  text-transform: uppercase;
}

.footer__quick-links .list-group-item:last-child {
  margin-bottom: 1rem;
}

.footer__quick-links .list-group-item {
  color: var(--section-color);
  font-family: var(--paragarph-text-font);
  font-weight: var(--weight-400);
  font-size: 0.865rem;
  line-height: 1.3rem;
  cursor: pointer;
}
.footer__quick-links .list-group-item:hover {
  color: var(--hover-color);
  transition: all 0.4s var(--transition);
}
.social__icons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
}

@media (max-width: 768px) {
  .footer {
    padding-inline: 1rem;
  }

  .footer .footer__info {
    gap: 1rem;
  }

  .social__icons {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .footer .footer__info {
    flex-direction: row;
    border-bottom: 1px solid var(--mega-menu-card-color);
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
  }

  .footer .footer__info .logo,
  .footer .footer__info .footer__decsription {
    width: 50%;
  }

  .footer .col-lg-2 {
    border-bottom: 1px solid var(--mega-menu-card-color);
    margin-bottom: 1rem;
    width: 80%;
  }
  .footer__decsription p {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .footer__title {
    font-size: 0.9rem;
  }
  .footer .footer__info {
    gap: 1rem;
    padding: 0;
  }
  .footer .footer__info .logo {
    width: 40% !important;
  }

  .footer .footer__info .logo img {
    width: 220px;
  }
  .footer .footer__info .footer__decsription {
    width: 60% !important;
  }

  .footer .footer__info .footer__decsription p {
    font-size: 9px;
    line-height: 1rem;
    width: 100%;
  }
}

@media (max-width: 1280px) {
  .footer__decsription p {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }

  .footer__quick-links .list-group-item {
    font-size: 0.768rem;
  }
}

@media (max-width: 1024px) {
  .logo img {
    width: 220px;
    height: 50px;
  }
  .footer__link-title {
    font-size: 0.9rem;
  }

  .footer__decsription p {
    font-size: 0.768rem;
    line-height: 1.5rem;
  }

  .footer__quick-links .list-group-item {
    font-size: 0.768rem;
  }

  .social__icons {
    gap: 3rem;
  }
}

@media (min-width: 1200px) {
  .footer .row {
    gap: 0.5rem !important;
  }

  .footer .col-lg-5 {
    width: 39%;
  }

  .footer .col-lg-2 {
    width: 16.3333333%;
  }

  .footer .col-lg-1 {
    width: 9%;
  }

  .social__icons {
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem !important;
  }
}

@media (max-width: 522px) {
  .footer {
    padding-inline: 1rem;
  }

  .footer__quick-links .list-group-item {
    font-size: 0.7rem !important;
  }

  .footer__link-title {
    font-size: 0.8rem;
    line-height: 2rem;
  }
}

@media (max-width: 991.9px) and (min-width: 922px) {
  .footer .col-lg-5 {
    width: 30%;
  }

  .footer .col-lg-2 {
    width: 21%;
  }

  .footer .col-lg-1 {
    width: 7%;
  }

  .footer__info .logo img {
    width: 200px;
  }

  .footer__info .footer__decsription p {
    font-size: 0.7rem;
    line-height: 1.4rem;
    width: 100%;
  }

  .footer__link-title {
    font-size: 0.865rem;
    line-height: 2rem;
  }

  .footer__quick-links .list-group-item {
    font-size: 0.7rem !important;
    line-height: 1.5rem;
  }

  .social__icons img {
    width: 15px;
  }

  .social__icons .facebook-icon {
    width: 10px;
  }
}

@media (max-width: 921.9px) {
  .footer .footer__info {
    gap: 1rem;
  }

  .social__icons {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .footer .footer__info {
    flex-direction: row;
    border-bottom: 1px solid var(--mega-menu-card-color);
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .footer .footer__info .logo,
  .footer .footer__info .footer__decsription {
    width: 50%;
  }

  .footer .col-lg-2 {
    border-bottom: 1px solid var(--mega-menu-card-color);
    margin-bottom: 1rem;
    width: 80%;
  }
}

.arrowbtn {
  position: fixed;
  width: 38px;
  height: 38px;
  right: 1%;
  bottom: 10%;
  cursor: pointer;
  z-index: 999;
  /* top: 4185px; */

  background: #012e54;
  border-radius: 2px;
  color: var(--body-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

/* LiveBar */

.livebar {
  padding-right: 3rem;
  height: 42px;
  background: var(--basic-color);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  gap: 1rem;
  padding-left: 3rem;
  width: 100%;
  position: fixed;
  bottom: 0%;
  z-index: 999;
}

.livebar a,
.livebar i {
  font-family: "Trebuchet MS";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 0;
  color: #ffffff;
}

.livebar i {
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .livebar {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 522px) {
  .livebar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .livebar a {
    font-size: 10px;
    line-height: 15px;
  }
  .livebar img {
    width: 40px;
    height: auto;
  }
}

@media (max-width: 375px) {
  .livebar {
    padding: 0.3rem;
  }
  .livebar a {
    font-size: 9px;
    line-height: 13px;
  }
  .livebar img {
    width: 35px;
    height: auto;
  }
}
