.newsletter {
  background-color: #f5f5f5;
  padding-block-start: 1.5rem;
  /* padding-block-end: 1rem; */
}

.newsletter img {
  height: 100px;
}

.input-group {
  width: 100%;
  height: 56px;
  border-radius: 4px;
}

.input-group input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.1rem 1rem;
  gap: 10px;
  border-radius: 0.25rem;
  border: none;
  color: var(--input-text-color);
}

.input-group .btn {
  width: 30%;
  height: auto;
  color: var(--body-color);
  font-size: 16px;
  font-weight: var(--weight-400);
  font-family: var(--paragarph-text-font);
  text-align: center;
  padding: 16px 30px 16px 30px;
  line-height: 21px;
  border: none;
  border-radius: 0px, 4px, 4px, 0px;
  background-color: var(--basic-color);
}

.input-group .btn:hover {
  background-color: var(--hover-color);
  color: var(--body-color);
  transition: all 0.4s var(--transition);
}

.newsletter__text h1 {
  color: var(--basic-color);
  font-family: var(--section-title-font);
  font-weight: var(--weight-600);
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
}

.newsletter__text p {
  color: var(--section-color);
  font-family: var(--section-title-font);
  font-weight: var(--weight-300);
  font-size: 1.5rem;
  line-height: 2rem;
}

/* @media (max-width: 576px) {
  .newsletter img {
    width: 75px;
  }

  .newsletter__text h1,
  .newsletter__text p {
    font-size: 23px;
    line-height: 20px;
  }

  .newsletter {
    padding-inline: 1rem;
  }

  .input-group {
    margin-top: 2rem;
  }

  .input-group input,
  .input-group .btn {
    font-size: 15px;
  }

  .input-group .btn {
    width: 90px !important;
    height: auto;
  }
}

@media (max-width: 300px) {
  .newsletter__text h1 {
    font-size: 20px !important;
  }
  .newsletter__text p {
    font-size: 16px !important;
    line-height: 19px !important;
  }

  .input-group input,
  .input-group .btn {
    font-size: 12px;
  }

  .input-group .btn {
    width: 70px !important;
    height: auto;
  }
}
 */

@media (max-width: 1320px) {
  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1.25rem;
    line-height: 1.8rem;
  }

  .newsletter img {
    height: 90px;
  }

  .input-group input {
    font-size: 0.865rem;
  }

  .input-group .btn {
    font-size: 0.865rem;
  }
}

@media (max-width: 1200px) {
  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .newsletter img {
    height: 85px;
  }

  .input-group input {
    padding: 14px 25px;
    font-size: 0.7rem;
  }

  .input-group .btn {
    font-size: 0.7rem;
    padding: 14px 25px;
  }
}

@media (max-width: 1024px) {
  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .newsletter img {
    height: 80px;
  }

  .input-group input {
    padding: 13px 23px;
    font-size: 0.625rem;
  }

  .input-group .btn {
    font-size: 0.625rem;
    padding: 13px 23px;
  }
}

@media (max-width: 992px) {
  .input-group {
    margin-left: auto;
    margin-right: auto;
    width: 60% !important;
    height: 50px;
    justify-content: center;
  }

  .input-group input {
    font-size: 0.8rem;
  }

  .input-group .btn {
    font-size: 0.8rem;
  }

  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .newsletter img {
    height: 90px;
  }
}

@media (max-width: 768px) {
  .input-group {
    margin-left: auto;
    margin-right: auto;
    width: 70% !important;
    height: 50px;
    justify-content: center;
  }

  /* .input-group input {
    font-size: 0.8rem;
  } */

  /* .input-group .btn {
    font-size: 0.8rem;
  } */

  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .newsletter img {
    height: 85px;
  }
}

@media (max-width: 576px) {
  .newsletter img {
    width: 90px;
  }

  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .newsletter {
    padding-inline: 1rem;
  }

  /* .input-group {
    margin-top: 2rem;
  } */

  .input-group {
    margin-left: auto;
    margin-right: auto;
    width: 80% !important;
    height: 50px;
    justify-content: center;
  }
  .input-group input,
  .input-group .btn {
    font-size: 0.768rem;
  }
}

@media (max-width: 430px) {
  .newsletter img {
    width: 90px;
  }

  .newsletter__text h1,
  .newsletter__text p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .newsletter {
    padding-inline: 0.8rem;
  }

  .input-group {
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
    height: 50px;
    justify-content: center;
  }

  .input-group input,
  .input-group .btn {
    font-size: 0.7rem;
  }
}

@media (max-width: 375px) {
  .newsletter__text p {
    font-size: 18px;
    /* line-height: 20px; */
  }

  .newsletter {
    padding-inline: 0.5rem;
  }

  .newsletter img {
    width: 85px;
  }

  .newsletter__text h1,
  .newsletter__text p {
    font-size: 0.9rem;
    line-height: 1.5rem;
  }

  .input-group input,
  .input-group .btn {
    font-size: 0.625rem;
  }
}

@media (max-width: 280px) {
  .newsletter__text p {
    font-size: 13px;
    /* line-height: 20px; */
  }

  .newsletter__text h1 {
    font-size: 15px;
  }

  .newsletter {
    padding-inline: 1rem;
  }

  .input-group {
    margin-top: 2rem;
  }

  .input-group input,
  .input-group .btn {
    font-size: 10px;
  }

  .input-group .btn {
    width: 70px !important;
    height: auto;
  }
}
