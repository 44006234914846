.popover {
  max-width: 100%;
  width: 97%;
}

.mantine-ScrollArea-root {
  height: auto !important;
}
.m_b8a05bbd {
  padding-inline: 0rem;
}

.navbar-expand {
  background-color: var(--body-color) !important;
}

.logo:hover {
  transform: translateY(-10px);
  transition: all 1s var(--transition);
}

.usermenu-drop .mantine-Menu-item {
  border-bottom: 1px solid #f5f5f5;
  border-radius: 0;
}

.mantine-Menu-itemLabel {
  font-family: var(--paragarph-text-font);
  font-weight: var(--weight-400);
  color: #012e54;
}

.m_760d1fb1:where([data-with-label]) {
  font-size: 0.525rem;
}

.header {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  background-color: var(--body-color) !important;
}
.navbar-toggler {
  border: none !important;
}

.navbar-brand {
  width: 13rem;
}

.nav__links .nav-link,
.nav__links .special__design {
  color: var(--section-color) !important;
  font-family: var(--paragarph-text-font);
  font-weight: var(--weight-400);
  font-size: 0.9rem;
  line-height: 1.25rem;
}

.nav__links .special__design {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 11.5625rem;
  height: 2.3125rem;

  background: #012e54;
  border-radius: 5px;

  font-weight: 700;
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration: none;
  color: #37e0b1 !important;
}
/* 
.header .search-input {
  width: 0;
  transition: width 0.5s;
} */

.header .search_input-show {
  position: relative;
  opacity: 0;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.5s ease, opacity 0.5s ease;
}

.nav-link:focus,
.nav-link:hover,
.special__design:focus {
  color: var(--basic-color) !important;
}

.nav__links .special__design:hover {
  color: var(--body-color);
  transition: all 0.4s var(--transition);
  font-weight: 700;
}

.user__action .nav-link {
  color: #264065;
  font-weight: var(--weight-400);
  font-family: var(--paragarph-text-font);
  font-size: 1rem;
  line-height: 1.25rem;
  text-decoration-line: underline;
}

/* .mega_menu:hover {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  transition: all 0.7s var(--transition);
} */

/* .nav__links {
  gap: 1rem;
  align-items: center;
} */

/* .user__action {
  justify-content: flex-start;
  align-items: center;
  gap
} */

.m_75f3ecf {
  direction: rtl;
  padding-left: 2rem !important;
}

.m_f6970eb1 {
  /* margin-bottom: 1rem !important; */
  padding-bottom: 1rem !important;
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
}

/* .user-menu.m_38a85659 {
  height: 20rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.user-menu .m_dc9b7c9f::-webkit-scrollbar {
  display: none;
} */

.scoring-point .m_760d1fb1 {
  border: 1px solid var(--bullets-bgcolor);
  font-size: 0.525rem;
  width: 2rem;
}

.user__action .sigup-btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;

  width: 9.3125rem;
  height: 3.75rem;

  background: #37e0b1;
  border-radius: 2rem;

  font-family: "Trebuchet MS";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3rem;
  text-align: center;
  text-decoration: none;

  color: #ffffff;
}

@media screen and (max-width: 1375px) {
  .navbar-brand {
    width: 12rem;
  }

  /* .navbar-expand-lg .navbar-nav {
    gap: 1rem !important;
  } */

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 0.945rem;
  }

  .nav__links .special__design {
    padding: 8px 16px;
    width: 11rem;
    height: 2.2rem;
    font-size: 0.945rem;
    line-height: 1rem;
  }

  .user__action .sigup-btn {
    width: 9.2rem;
    height: 3.5rem;

    font-size: 1.1rem;
    line-height: 1.25rem;
  }
}

@media screen and (max-width: 1280px) {
  .navbar-brand {
    width: 10.5rem;
  }

  /* .navbar-expand-lg .navbar-nav {
    gap: 1rem !important;
  } */

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 0.8rem;
  }

  .nav__links .special__design {
    padding: 6px 14px;
    width: 10.3rem;
    height: 2.1rem;
    font-size: 0.865rem;
    line-height: 1rem;
  }

  .user__action .sigup-btn {
    width: 8rem;
    height: 3.2rem;

    font-size: 1.1rem;
    line-height: 1.25rem;
  }

  .user__action .search__icon {
    width: 32px !important;
    height: 32px !important;
  }
}

@media screen and (max-width: 1200px) {
  .navbar-brand {
    width: 10rem;
  }

  .navbar-expand-lg .navbar-nav {
    gap: 1rem !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 0.8rem;
  }

  .nav__links .special__design {
    padding: 6px 14px;
    width: 10rem;
    height: 2rem;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .user__action .sigup-btn {
    width: 7.5rem;
    height: 3rem;

    font-size: 0.9rem;
    line-height: 1.25rem;
  }
  .user__action .search__icon {
    width: 30px !important;
    height: 30px !important;
  }
}

@media screen and (max-width: 1163px) {
  .navbar-brand {
    width: 9.7rem;
  }

  .navbar-expand-lg .navbar-nav {
    gap: 1rem !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 0.768rem;
  }

  .nav__links .special__design {
    padding: 6px 14px;
    width: 9.3rem;
    height: 2rem;
    font-size: 0.768rem;
    line-height: 1rem;
  }

  .user__action .sigup-btn {
    width: 7rem;
    height: 2.8rem;

    font-size: 0.865rem;
    line-height: 1.25rem;
  }
  .user__action .search__icon {
    width: 27px !important;
    height: 27px !important;
  }
}

@media screen and (max-width: 1080px) {
  .navbar-brand {
    width: 9.5rem;
  }

  .navbar-expand-lg .navbar-nav {
    gap: 0.9rem !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 0.75rem;
  }

  .nav__links .special__design {
    padding: 6px 14px;
    width: 8.7rem;
    height: 2rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .user__action .sigup-btn {
    width: 6.5rem;
    height: 2.5rem;

    font-size: 0.8rem;
    line-height: 1.25rem;
  }
  .user__action .search__icon {
    width: 26px !important;
    height: 26px !important;
  }

  .user__action {
    gap: 0.768rem !important;
  }
}

@media (max-width: 991.9px) {
  .navbar-brand {
    width: 12rem;
  }

  .nav__links {
    gap: 1rem !important;
  }

  .nav__links .nav-link {
    font-size: 0.9rem;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.3rem;
    width: 100%;
  }

  .nav__links .special__design {
    padding: 6px 14px;
    width: 10rem;
    height: 2.2rem;
    font-size: 0.9rem;
    line-height: 1rem;
    margin-bottom: 1.5rem;
  }

  .user__action .sigup-btn {
    /* width: 7.5rem;
    height: 2.8rem; */

    font-size: 1rem;
    line-height: 1.25rem;
    text-decoration: underline;
    background: none;
    color: #264065;
    font-weight: var(--weight-400);
  }
  .user__action .search__icon {
    width: 30px !important;
    height: 30px !important;
  }
}

@media (max-width: 575.9px) {
  .navbar-nav .user__action {
    gap: 0.8rem !important;
    justify-content: flex-start !important;
  }
  .header {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .user__action .search__icon {
    display: none !important;
  }

  .navbar-brand {
    width: 11rem;
  }

  .nav__links .nav-link {
    font-size: 0.865rem;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.2rem;
    width: 100%;
  }

  .nav__links .special__design {
    padding: 6px 14px;
    width: 9.1rem;
    height: 2.1rem;
    font-size: 0.8rem;
    line-height: 1rem;
    margin-bottom: 1.5rem;
  }

  .user__action .sigup-btn {
    /* width: 7.5rem;
    height: 2.8rem; */

    font-size: 1rem;
    line-height: 1.25rem;
    text-decoration: underline;
    background: none;
    color: #264065;
    font-weight: var(--weight-400);
  }

  .user__action .search__icon {
    width: 27px !important;
    height: 27px !important;
  }
}
