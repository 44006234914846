html::-webkit-scrollbar {
  width: 6px;
}

html::-webkit-scrollbar-track {
  background: #fff;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--basic-color);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background-color: var(--hover-color);
}

/* selsect in sign up page  */
.css-13cymwt-control {
  height: 3rem !important;
  border-radius: 0.5rem !important;
  cursor: text !important;
}

.css-13cymwt-control:focus {
  height: 3rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid #37e0b1 !important;
}

.mantine-Checkbox-body {
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.m_8fb7ebe7 {
  border-radius: 0.5rem !important;
}

.m_8fb7ebe7:focus {
  border: 1px solid #37e0b1 !important;
  outline: none !important;
  box-shadow: var(--shadow) !important;
}

[data-mantine-color-scheme='light'] .m_6c018570[data-variant='default'] {
  --input-bd-focus: #37e0b1 !important; 
  --input-outline: none !important; 
  --input-box-shadow: var(--shadow) !important; 
}

.css-13cymwt-control::selection {
  border: 1px solid #37e0b1 !important;
  outline: none !important;
  box-shadow: var(--shadow) !important;
}

.mantine-Checkbox-body{
  gap: 4px;
  align-items: flex-start;
}

.m_b5489c3c{
  background: none !important;
}

.ql-editor{
  min-height: 150px !important;
}
