.search__header {
  height: 300px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/search-header.png");
}

.search__container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(1, 46, 84, 0) 0%, #012e54 100%);
}

.search__header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: color;
  background: linear-gradient(
    179.96deg,
    #aaffe7 7.02%,
    #40a7ff 49.93%,
    #012e54 99.97%
  );
}

.search__header__title {
  position: absolute;
  /* width: 876px;
height: 67px; */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 68px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

/* search Navigation Taps */

.search__nav__tabs {
  padding-block: 2rem;
}

.search__nav__tabs .nav {
  flex-wrap: nowrap !important;
  /* overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none; */
}

/* .search__nav__tabs .nav::-webkit-scrollbar {
  display: none;
} */

.search__nav__tabs .nav-link {
  font-family: "Trebuchet MS";
  font-weight: 400;
  font-size: 0.825rem;
  line-height: 1.1rem;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #012e54;
  transition: all 0.8s var(--transition);
}

.search__nav__tabs .nav-link.active {
  color: var(--basic-color) !important;
}

/* Result Number */
.result__number {
  padding-block-start: 2rem;
  padding-block-end: 1rem;
}

.result__number p {
  /* width: 187.56px;
height: 31px; */
  width: 100%;
  font-family: "Trebuchet MS";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  color: #012e54;
  border-bottom: 4px solid var(--basic-color);
  border-radius: 4px;
}

/* Search Result Data */
.search__data {
  padding-block: 2rem;
}

.search__data .row {
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
}

/* Previous Searches */
.search__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.768rem;
  gap: 1rem;

  width: 100%;

  background: #ffffff;
  box-shadow: 0px 0px 9.60427px rgba(1, 46, 84, 0.1);
  border-radius: 12.0053px;
}

.search__card .search__card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
  width: 100%;
}

.search__card__content h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  color: #37e0b1;
  width: 100%;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #f5f5f5;
}

.search-data__card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px 0px;
  gap: 12px;
}

.search-data__card p,
.single__result p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: #012e54;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #f5f5f5;
}

.search-data__card p span,
.single__result p span {
  color: var(--basic-color);
  margin-right: 4px;
}

/* Word Matching Result */
.word-search__result,
.single__result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.word-search__result {
  gap: 1.5rem;
}

.single__result {
  border-bottom: 1px solid #f5f5f5;
}

.single__result .title {
  border: none;
  padding: 0;
}

.tag__card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 2rem;
  width: 100%;
}

.tag__card .tag {
  width: 150px;
  height: 30px;

  background: #f1fffb;
  mix-blend-mode: normal;
  border-radius: 15px;

  font-family: "Trebuchet MS";
  font-style: normal;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1.62px;
  text-transform: uppercase;

  color: #00a275;
}

.tag__card p {
  font-family: "Trebuchet MS";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 24px;
  color: #000000;
  padding: 0;
  border: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Pagination */
.search__data .m_4081bf90 {
  justify-content: center !important;
}
/* Responsive */

@media screen and (min-width: 320px) {
  .search__header__title,
  .result__number p {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .search__header {
    height: 150px;
  }
  .search__nav__tabs,
  .result__number,
  .search__data {
    padding-inline: 0rem;
    padding-block: 1rem;
  }

  .search__card {
    padding: 1rem;
    width: 100%;
  }
  .search-data__card p,
  .single__result p {
    font-size: 0.768rem;
  }
  .tag__card {
    gap: 1.25rem;
  }
  .tag__card .tag {
    font-size: 0.5rem;
  }
  .tag__card p {
    width: 100%;
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 375px) {
  .search__header__title,
  .result__number p {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .search__header {
    height: 150px;
  }
  .search__nav__tabs,
  .result__number,
  .search__data {
    padding-inline: 0.5rem;
    padding-block: 1rem;
  }

  .search-data__card p,
  .single__result p {
    font-size: 0.8rem;
  }
  .tag__card {
    gap: 1.25rem;
  }
  .tag__card .tag {
    font-size: 0.575rem;
  }
  .tag__card p {
    width: 100%;
    font-size: 0.768rem;
  }
}

@media screen and (min-width: 575px) {
  .search__header__title
  /* ,.result__number p  */ {
    font-size: 1.375rem;
    line-height: 2rem;
  }
  .search__header {
    height: 170px;
  }
  .search__nav__tabs,
  .result__number,
  .search__data {
    padding-inline: 0.768rem;
    padding-block: 1rem;
  }

  .search-data__card p,
  .single__result p {
    font-size: 0.865rem;
  }
  .tag__card {
    gap: 1.5rem;
  }
  .tag__card .tag {
    font-size: 0.6rem;
  }
  .tag__card p {
    width: 100%;
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 768px) {
  .search__header__title
  /* ,.result__number p  */ {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .search__header {
    height: 190px;
  }
  .search__nav__tabs,
  .result__number,
  .search__data {
    padding-inline: 1rem;
    padding-block: 1rem;
  }

  .result__number .container,
  .search__data .container {
    margin: 0;
  }

  .search__card {
    width: 48%;
    margin-left: auto;
    margin-right: auto;
  }

  .search-data__card p,
  .single__result p {
    font-size: 0.9rem;
  }
  .tag__card {
    gap: 2rem;
  }
  .tag__card .tag {
    font-size: 0.7rem;
  }
  .tag__card p {
    width: 100%;
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 992px) {
  .search__card {
    padding: 1.125rem;
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .search__header__title
  /* ,.result__number p  */ {
    font-size: 2rem;
    line-height: 2rem;
  }
  .search__header {
    height: 200px;
  }
  .search__card {
    padding: 1.125rem;
    width: 100%;
  }
  .search__nav__tabs,
  .result__number,
  .search__data {
    padding-inline: 1.5rem;
  }

  .search-data__card p,
  .single__result p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1280px) {
  .search__header__title
  /* ,.result__number p  */ {
    font-size: 1.768rem;
    line-height: 2rem;
  }
  .search__header {
    height: 200px;
  }
  .search__card {
    padding: 1.25rem;
  }
  .search__nav__tabs,
  .result__number,
  .search__data {
    padding-inline: 3rem;
    padding-block: 2rem;
  }

  .tag__card .tag {
    font-size: 0.768rem;
  }
}

@media screen and (min-width: 1400px) {
  .search__header__title {
    font-size: 2rem;
    line-height: 2rem;
  }
  .search__header {
    height: 250px;
  }

  .search__card {
    padding: 1.375rem;
  }

  .search-data__card p,
  .single__result p {
    font-size: 1.125rem;
  }

  .tag__card .tag {
    font-size: 0.8rem;
  }

  .tag__card p {
    width: 100%;
    font-size: 1rem;
  }

  .search__nav__tabs .nav-link {
    font-size: 1rem;
  }
}
