@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

/* BASICS COLORS & FONTS VARIABLES */
:root {
  /* Colors */
  --basic-color: #37e0b1;
  --section-color: #012e54;
  --body-color: #fff;
  --text-color: #282828;
  --input-text-color: #ababab;
  --bullets-bgcolor: #d2d5da;
  --premium-bgcolor: #e0cf37;
  --mega-menu-card-color: #f7f7fb;
  --hover-color: #00a275;

  /* Fonts Style */
  --section-title-font: "Montserrat", sans-serif;
  --paragarph-text-font: "Trebuchet MS";

  /* Font Size */

  /* Font Weigt */
  --weight-300: 300;
  --weight-400: 400;
  --weight-500: 500;
  --weight-600: 600;
  --weight-700: 700;
  --weight-800: 800;
  --weight-900: 900;

  /* Border Radius */
  --section-btn-radius: 30px;
  --card-btn-radius: 20px;
  --action-btn-radius: 50%;

  /* Box Shadow */
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Box Shadow */
  --shadow: 0px 5px 20px 0px rgb(96 67 96 / 10%);

  /* Transition */
  --transition: cubic-bezier(0, 0, 0.05, 1);
}

/* BASE */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  /* border: none; */
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

section,
footer {
  padding-inline: 3rem;
}
.special__text {
  color: var(--body-color);
  font-family: var(--section-title-font);
  font-weight: var(--weight-900);
  font-size: 8.625rem !important;
  text-align: center;
  text-transform: uppercase;
  line-height: 12.5rem;
  opacity: 0.05;
}

.mantine-Modal-content {
  padding: 0;
  z-index: 10000;
}

.swiper-wrapper {
  width: 100%;
  margin-left: 0; /* Ensure no margin is added */
}

.swiper-slide {
  width: auto; /* Ensure slides don't cause overflow */
}

.section-class {
  /* Ensure the section containing the Swiper has no padding */
  padding-right: 0;
  padding-left: 0;
}

@layer utilities {
  .custom-width-important {
    @apply w-[32%];
    width: 32% !important;
  }
  .custom-baseline {
    @apply flex items-baseline;
    align-items: center;
    justify-content: baseline;
    padding: 0px;
    gap: 2rem;
    flex-wrap: wrap;
  }
  .blur-background {
    @apply fixed top-0 left-0 w-full h-full;
    backdrop-filter: blur(10px);
  }
}

@media (max-width: 1024px) {
  .special__text {
    font-size: 7.2rem !important;
  }
}

@media (max-width: 768px) {
  .special__text {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .special__text {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .special__text {
    display: block !important;
    font-size: 7rem !important;
  }
}

@media (min-width: 1200px) {
  .special__text {
    font-size: 8rem !important;
  }
}

@media (max-width: 375px) {
  .pagination {
    gap: 0.2rem !important;
  }
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: var(--basic-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--hover-color);
}

@media (max-width: 1024px) {
  section {
    padding-inline: 1.5rem !important;
  }
}
@media (max-width: 786px) {
  section {
    padding-inline: 0.5rem !important;
  }
}
