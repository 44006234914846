.mega-menu {
  padding-inline: 0rem;
}

.link__title h3 {
  color: var(--basic-color);
  font-family: var(--section-title-font);
  font-weight: var(--weight-800);
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.mega-menu__links .list-group-item {
  color: var(--section-color);
  font-family: var(--paragarph-text-font);
  font-weight: var(--weight-400);
  font-size: 1rem;
  line-height: 1.25rem;
  cursor: pointer;
}

.mega-menu__links .list-group-item:hover {
  color: var(--basic-color);
  transition: all 0.4s var(--transition);
}

.mega-menu__card {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  gap: 2rem;
  padding-left: 2rem;
  width: 17.5rem;
  height: 6.875rem;
  margin-bottom: 1rem;
  border-radius: 14px;
  background: var(--mega-menu-card-color);
}

.icon__card {
  width: 70px;
  height: 70px;
  background: var(--body-color);
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(23, 15, 73, 0.08);
}

.icon__card img {
  display: block;
  margin: 0.8rem auto;
}

.menu__info {
  margin-top: 1.25rem;
}

.menu__info h3 {
  color: var(--section-color);
  font-family: var(--paragarph-text-font);
  font-weight: var(--weight-700);
  font-size: 1rem;
  line-height: 1.25rem;
}

.menu__info p {
  color: var(--basic-color);
  font-family: var(--paragarph-text-font);
  font-weight: var(--weight-400);
  font-size: 1rem;
  line-height: 1.25rem;
  margin-right: 0.3rem;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .mega-menu__links .list-group-item {
    font-size: 0.8rem;
  }

  .link__title h3 {
    font-size: 0.9rem;
  }
  .mega-menu__card {
    gap: 1.5rem;
    padding-left: 1.5rem;
    height: 100px;
  }

  .icon__card {
    width: 70px;
    height: 70px;
  }

  .icon__card img {
    display: block;
    margin: 0.8rem auto;
  }

  .menu__info {
    margin-top: 1.25rem;
  }

  .menu__info h3 {
    font-size: 0.9rem;
  }

  .menu__info p {
    font-size: 0.9rem;
    line-height: 1.25rem;
    margin-right: 0.3rem;
  }
}

@media (max-width: 1160px) {
  .mega-menu__links .list-group-item {
    font-size: 0.768rem;
  }

  .link__title h3 {
    font-size: 0.8rem;
  }
  .mega-menu__card {
    gap: 1.2rem;
    padding-left: 1.3rem;
    height: 90px;
  }

  .icon__card {
    width: 60px;
    height: 60px;
  }

  .icon__card img {
    display: block;
    margin: 0.8rem auto;
    width: 35px;
    height: 35px;
  }

  .menu__info {
    margin-top: 1.25rem;
  }

  .menu__info h3 {
    font-size: 0.768rem;
  }

  .menu__info p {
    font-size: 0.8rem;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .mega-menu__card {
    width: 222px;
  }

  .icon__card {
    width: 50px;
    height: 50px;
  }

  .icon__card img {
    display: block;
    margin: 0.7rem auto;
    width: 30px;
    height: 30px;
  }

  .menu__info {
    margin-top: 1.25rem;
  }
}

@media (min-width: 992px) {
  .mega-menu__card {
    width: auto;
    /* max-width: 290px; */
  }
}

@media (min-width: 1375px) {
  .mega-menu__card {
    width: 18rem;
  }
}
